// eslint-disable-next-line import/no-extraneous-dependencies
import { z } from 'zod';

// eslint-disable-next-line import/prefer-default-export
export const checkNotEmptyStringSchema = (fields) => {
  const schemaDefinition = {};

  fields.forEach((field) => {
    schemaDefinition[field] = z.string().min(1, 'Поле не может быть пустым');
  });

  return z.object(schemaDefinition);
};
